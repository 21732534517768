// import Box from "../components/Atomic/Box"
import React from "react"
import PageStructure from "../components/PageStructure"

export default function B306({ location }) {
  return (
    <PageStructure
      pageTitle={`B306`}
      metaContent={`Recreation of my first ever game`}
      location={location}
    >
      {/* Content */}
      {/* End of Content */}
    </PageStructure>
  )
}
